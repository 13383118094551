@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.min-h-screen {
  min-height: 100vh;
}

.gap-8 > * + * {
  margin-top: 2rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.md\\:px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.space-y-4 > * + * {
  margin-top: 1rem;
}

.text-center {
  text-align: center;
}

.text-3xl {
  font-size: 1.875rem;
}

.font-bold {
  font-weight: 700;
}

.sm\\:text-4xl {
  font-size: 2.25rem;
}

.md\\:text-5xl {
  font-size: 3rem;
}

.text-gray-500 {
  color: #6b7280;
}

.md\\:text-xl {
  font-size: 1.25rem;
}

.dark\\:text-gray-400 {
  color: #cbd5e0;
}

.w-full {
  width: 100%;
}

.max-w-300 {
  max-width: 300px;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.bg-gray-100 {
  background-color: #f3f4f6;
}

.dark\\:bg-gray-800 {
  background-color: #1f2937;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.text-xs {
  font-size: 0.75rem;
}

.dark\\:text-gray-400 {
  color: #cbd5e0;
}
