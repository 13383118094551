.card {
  width: 100%;
  max-width: 300px;
  height: 500px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.cardcontent {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}

.cardprimarybutton {
  background-color: #1b1b1b;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
}
